import React, { useState } from 'react';
import { Link } from 'gatsby';
//import { render } from "react-dom";
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { Container, Row, Col } from 'reactstrap';
import HeroCarousel from '../../components/carousel/property-carousel';
import BeforeAfterCarousel from '../../components/carousel/beforeafter-carousel';
import PortfolioPagination from '../../components/portfolio/pagination';

const SinglePortfolio = (props) => {
    const [count, setCount] = useState(0);
    const {
        pageContext: { title, id, acf_properties, acf_page_meta },
    } = props;

    return (
        <Layout>
            <SEO title={title} description={acf_page_meta.metadescription} />
            <article>
                <div>
                    <section className="section__investment-carousel">
                        {acf_properties.carouselImg != null && (
                            <HeroCarousel items={acf_properties.carouselImg} />
                        )}
                    </section>
                    <section className="section__investment-info">
                        <Container className="pl-0">
                            <Row>
                                <Col
                                    md={{ size: 6, order: 2 }}
                                    lg={{ size: 4 }}
                                    className="investment-info__column mx-0 px-0"
                                >
                                    <section className="section__investment-stats">
                                        <Container>
                                            <Row>
                                                <Col>
                                                    <h1 className="heading1 d-md-none">{title}</h1>
                                                </Col>
                                            </Row>
                                            <div className="property-info">
                                                <Row>
                                                    <Col>
                                                        <h4 className="investment__label">
                                                            Location
                                                        </h4>
                                                        <p className="heading4--bold">
                                                            {acf_properties.address.city},{' '}
                                                            {acf_properties.address.state}
                                                        </p>
                                                        <h4 className="investment__label">Year</h4>
                                                        <p className="heading4--bold">
                                                            {acf_properties.details.year}
                                                        </p>
                                                    </Col>
                                                    <Col>
                                                        <h4 className="investment__label">Units</h4>
                                                        <p className="heading4--bold">
                                                            {acf_properties.details.units}
                                                        </p>
                                                        {/* {acf_properties.details.returns && (
                                                            <>
                                                                <h4 className="investment__label">
                                                                    Projected Returns
                                                                </h4>
                                                                <p className="heading4--bold">
                                                                    {acf_properties.details.returns}
                                                                </p>
                                                            </>
                                                        )} */}
                                                        {acf_properties.details.achievedreturn && (
                                                            <>
                                                                <h4 className="investment__label">
                                                                    Achieved Return
                                                                </h4>
                                                                <p className="heading4--bold">
                                                                    {
                                                                        acf_properties.details
                                                                            .achievedreturn
                                                                    }
                                                                </p>
                                                            </>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Container>
                                    </section>
                                    <Row>
                                        <Col>
                                        {(acf_properties.websiteLink && acf_properties.websiteLink.length>0) && (
                                            <a
                                                className="cta-button mt-5 d-none d-md-block"
                                                href={acf_properties.websiteLink}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                Visit Website
                                            </a>
                                        )}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col
                                    className="investment-info__column"
                                    md={{ size: 8, order: 1 }}
                                    lg={{ size: 6, offset: 4 }}
                                >
                                    <section className="section__investment-details">
                                        <Container>
                                            <Row>
                                                <Col xs={{ size: 12, offset: 1 }}>
                                                    <h1 className="heading1 d-none d-md-block">
                                                        {title}
                                                        <br />
                                                    </h1>
                                                    <p className="body-copy mb-5">
                                                        {acf_properties.description}
                                                    </p>
                                                    <a
                                                        className="cta-button mb-5 d-block d-md-none"
                                                        href={acf_properties.websiteLink}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        Visit Website
                                                    </a>
                                                    <div className="community-highlights">
                                                        <h2 className="heading2">
                                                            Community Highlights
                                                        </h2>
                                                        <ul className="bulleted-list">
                                                            {acf_properties.highlights.map(
                                                                (highlights, i) => (
                                                                    <li key={i}>
                                                                        {highlights.highlight}{' '}
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </section>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    {acf_properties.beforeAndAfter != null && (
                        <Container>
                            <Row>
                                <Col className="pr-5" md={{ size: 5 }} lg={{ size: 5 }}>
                                    <h2 className="heading2">Creating Impact</h2>
                                    <p className="body-copy">
                                        Our strategic value additions to unit interiors and common
                                        area exteriors create a collective benefit among our
                                        residents, our company and our investors.
                                    </p>
                                    <p className="blockquote">
                                        Our goal isn't just to rehabilitate an asset, we're invested
                                        in totally reinvigorating it.
                                    </p>
                                </Col>

                                <Col
                                    md={{ size: 8, offset: 1 }}
                                    lg={{ size: 8, offset: 1 }}
                                    className="mx-0 px-0"
                                >
                                    <section className="section__investment-slider">
                                        <BeforeAfterCarousel
                                            items={acf_properties.beforeAndAfter}
                                            current={count}
                                        />
                                        <div className="carousel__thumbnails">
                                            {acf_properties.beforeAndAfter.map((thumb, i) => (
                                                <button
                                                    onClick={() => setCount(i)}
                                                    className="carousel__thumbnail"
                                                >
                                                    <img
                                                        className="w-100"
                                                        alt={`${thumb.afterImage.altText} thumbnail`}
                                                        src={
                                                            thumb.afterImage.imageFile
                                                                .childImageSharp.fluid.src
                                                        }
                                                    />
                                                </button>
                                            ))}
                                        </div>
                                    </section>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    <section className="section__investment-cta text-center">
                        <h2 className="heading1">
                            Interested in investing in a fund? It's simple.
                        </h2>
                        <Link to="/contact" className="cta-button cta-button--blue">
                            Contact Ideal
                        </Link>
                    </section>
                    <PortfolioPagination propid={id} />
                </div>
                <footer className="entry-footer" />
            </article>
        </Layout>
    );
};

export default SinglePortfolio;
